/*
 Theme Name:   Blankslate for poetryslam.ch 2022
 Description:  Relaunch 2022 Child Theme
 Author:       Poetryslam.ch
 Author URI:   https://solarplexus.ch
 Template:     blankslate
 Version:      1.0
 Text Domain:  blankslate-child-theme
*/

/* @import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;700&display=swap"); */
@font-face {
  font-family: "Barlow Condensed";
  src: url("./fonts/BarlowCondensed-Bold.woff2") format("woff2"),
    url("./fonts/BarlowCondensed-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow Condensed";
  src: url("./fonts/BarlowCondensed-Regular.woff2") format("woff2"),
    url("./fonts/BarlowCondensed-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --border: 4rem;
  --radius-inner: 2rem;
  --rosa: #ed77ac;
  --cardWidth: 57rem;
}

h1.entry-title {
  display: none !important;
}

* {
  box-sizing: border-box;
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

html {
  font-size: 10px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  min-height: 100%;
}

body {
  background-color: rebeccapurple;
  margin: 0;
  background-image: url("./images/bg.jpg");
  background-size: cover;
  min-height: 100vh;
}

#root,
.App,
.App-header,
.all-types {
  min-height: 100vh;
}

.text-center {
  text-align: center;
}

/* BRANDING */
header {
  text-align: center;
}

.brand {
  max-width: 20rem;
  margin-top: 6rem;
  height: auto;
}

/* CARD */
.section {
  padding: 10vw 20vw 3vw 20vw;
  text-align: center;
  width: 100%;
}

aside {
  width: 100%;
  height: clamp(20rem, 100vw, 88rem);
  max-width: var(--cardWidth);
  /* padding-top: 154.3859649122807%; */
  position: relative;
  display: inline-block;
}

.card {
  width: 100%;
  height: 100%;
  background-color: black;
  padding: clamp(2rem, 5vw, 5rem);
  border-radius: var(--border);

  position: absolute;
  left: 0;
  top: 0;

  text-align: left;

  -webkit-box-shadow: 5px 5px 15px -7px #000000;
  box-shadow: 5px 5px 15px -7px #000000;
}

.card-content {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: var(--radius-inner);
  padding: clamp(1rem, 3vw, 3rem);
}

.origin-brand,
.card .logo {
  max-width: 30%;
  height: auto;

  margin-top: 2rem;
  margin-bottom: 3rem;
}

svg.logo .st0 {
  fill: var(--rosa);
}

a {
  cursor: pointer;
}
/* INFO */
.info {
  padding: 3rem;
  background-color: white;
  color: black;
  text-transform: none;
  /* margin: 4rem; */
  border-radius: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--cardWidth);
}

.info,
.info p {
  font-size: 1.4rem;
  text-align: left;
  margin-bottom: 1rem;
  font-weight: 400;
}

h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.info ul {
  list-style: disc;
  margin-bottom: 1rem;
}

.info em {
  color: var(--rosa);
}

/* OVERLAY */
.overlay {
  position: fixed;
  top: 0;
  /* transform: translateY(-50%); */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(237, 119, 172, 0.96);
  z-index: 9;
  padding: 10vw 20vw 3vw 20vw;
  /* backdrop-filter: blur(10px); */
}

.close {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  margin: 2rem;
  z-index: 1;
}

.info-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.3rem;
  position: relative;
  top: -0.3rem;
}

.info-icon svg {
  width: 1.5rem;
  height: auto;
  fill: var(--rosa);
}

label {
  margin-right: 0 !important;
}

/* SWITCH OVERRIDE */
.filter-row {
  border-top: 1px black dotted;
}

.filter-row label {
  padding: 1rem 0;
}

.filter-switch .MuiTypography-root {
  font-size: 1.7rem !important;
  margin-left: 1rem !important;
  font-family: "Barlow Condensed", sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.filter-switch .Mui-checked {
  color: var(--rosa) !important;
}

.filter-switch .Mui-checked + .MuiSwitch-track {
  background-color: var(--rosa) !important;
}

/* Settings */
.flex {
  display: inline-flex;
  justify-content: space-between;
  margin-top: 4rem;
  width: 100%;
  max-width: var(--cardWidth);
}

.settings-icon {
  background-color: var(--rosa);
  border-radius: 100rem;
  width: 85%;
  margin: 0.5%;
  display: inline-block;
  /* position: fixed;
  left: 3rem;
  bottom: 3rem; */
}

.random {
  width: 100%;
}


.next {
  left: auto;
  right: 3rem;
}

.settings-icon svg {
  width: 100%;
  height: auto;
  display: block;
  fill: #482683;
}

.random .settings-icon {
  background-color: #482683;
}

.random svg {
  fill:var(--rosa);
}
.buttons {
  margin-bottom: 2rem;
}

/* QUIZ */
.answers {
  margin: 4rem 0;
}

.answers article {
  color: var(--rosa);
  border: 3px var(--rosa) solid;
  border-radius: 100rem;
  font-size: 3rem;
  margin-bottom: 1rem;
  padding: 0.3rem 1.5rem;
}

/* TYPE */
h5 {
  font-size: 2rem;
  text-transform: uppercase;
  color: white;
}
h6 {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: var(--rosa);
  margin-bottom: 2rem;
  position: relative;
}

p {
  font-size: clamp(2rem, 4vw, 4rem);
  line-height: 1.2;
}

p.prompt {
  font-size: clamp(1.5rem, 3vw, 3rem);
  margin-top: 1.5rem;
}

svg text {
  font-size: 4px;
  display: block;
  inline-size: 80%;
  line-break: auto;
}

/* UI */
.buttons {
  margin-top: 5rem;
}

.button {
  text-decoration: none;
}

button,
.button {
  font-family: "Barlow Condensed", sans-serif;
  background-color: transparent;
  font-size: 2.5rem;
  text-transform: uppercase;
  border-radius: 100rem;
  color: white;
  border: 2px var(--rosa) solid;
  padding: 1rem 2rem;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  background-color: var(--rosa);
}

button:hover,
.button:hover {
  background-color: white;
  color: black;
}

button.prime {
  background-color: white;
  color: black;
}

button.prime:hover {
  background-color: black;
  border-color: black;
  color: white;
}

/* FOOTER */
footer {
  background-color: #482683;
  padding: 4rem;
}

.true-footer {
  text-align: left;
  display: flex;
  align-items: baseline;
  height: auto;
}

.rulefactory {
  width: 14rem;
  margin-left: 2rem;
}

.blank {
  width: 30%;
  border-bottom: 3px solid black;
  display: inline-block;
}

p.spacing {
  margin-top: 2rem;
}

.card-icon {
  position: relative;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  font-size: 2.5rem;
  text-transform: uppercase;
  color: var(--rosa);
  margin-bottom: 2rem;
  position: relative;

  margin-top: 3rem;
}

.card-icon svg {
  width: 3rem;
  height: auto;
}

.reload-icon {
  text-align: center;
  margin-top: 6rem;
}

.reload-icon svg {
  width: 8rem;
  fill: #482683;

}

@media all and (max-width: 560px) {
  .section,
  .overlay {
    padding: 10vw 10vw;
    text-align: center;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; */
  }

  .all-types {
    display: flex;
    align-items: center;
  }

  .info {
    margin: 0;
    width: 80vw;
  }

  aside {
    height: auto;
    min-height: 120vw;
    display: block;
  }

  button,
  .button {
    font-size: 1.5rem;
    transform: scale(0.9);
  }

  .answers {
    margin: 1rem 0;
  }

  .answers article {
    font-size: 1.6rem;
    margin-bottom: 0.4rem;
  }

  h6 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .card-icon {
    font-size: 2rem !important;
  }
}
